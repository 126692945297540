@media (max-width: 1120px) {
  body #banner-part {
    width: 15%;
  }
}

@media (max-width: 908px) {
  body #serviceUL {
    width: 100% !important;
  }
  body .xinhao {
    width: 100% !important;
  }
  body .popup-content {
    width: 100% !important;
  }
  body #banner-part {
    display: none;
  }
  body #banner-area {
    width: 100% !important;
    text-align: center;
  }
  body #banner-area img {
    width: 100%;
  }
}

@media (max-width: 536px) {
  body #internal-banner .innerPageContent img {
    width: 100% !important;
    margin: auto !important;
  }
  body #internal-banner .xinhao {
    width: 100%;
  }
  body .popup-content {
    width: 90%;
  }
  body #tagline {
    font-size: 18px;
  }
  body header button {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  body nav.open {
    display: block;
  }
  body .material-symbols-outlined {
    position: fixed;
    right: 0;
    cursor: pointer;
    display: block;
    background: #f5f5f5;
    color: black;
    padding: 10px;
    border-radius: 4px 0px 0px 4px;
  }
  body nav {
    background: white;
    padding: 0px;
    position: fixed;
    width: 100%;
    display: none;
    right: 0px;
    z-index: 1;
    text-align: center;
    top: 0;
  }
  body nav #closeNav {
    color: #000;
    display: block;
    cursor: pointer;
    position: relative;
    text-align: right;
    right: 9px;
    top: 7px;
    width: 20px;
    float: right;
    font-weight: bold;
  }
  body nav .navbar-nav {
    padding: 0px;
  }
  body nav .navbar-nav .nav-item {
    display: block;
  }
  body nav .navbar-nav .nav-item a {
    display: block;
    background: #fff;
    color: #3b3b3b;
    padding: 8px 0px;
    z-index: 1;
    border-bottom: 1px solid #c8c8c6;
  }
  body nav .navbar-nav .nav-item a:hover,
  body nav .navbar-nav .nav-item a:active {
    background: #c8c8c6;
    color: #fff;
  }
  body #we-here #text {
    width: 100%;
  }
  body #we-here #pic {
    display: none;
  }
  body #testimonial .each-slide-effect > div {
    height: 225px;
  }
  body #testimonial #picture {
    width: 100%;
  }
  body #testimonial #testi {
    clear: both;
    text-align: center;
    width: 100%;
    padding: 10px;
  }
}
