body {
  @media (max-width: 1120px) {
    #banner-part {
      width: 15%;
    }
  }

  @media (max-width: 908px) {
    #serviceUL {
      width: 100% !important;
    }
    .xinhao {
      width: 100% !important;
    }
    .popup-content {
      width: 100% !important;
    }
    #banner-part {
      display: none;
    }
    #banner-area {
      width: 100% !important;
      text-align: center;
      img {
        width: 100%;
      }
    }
  }
  @media (max-width: 536px) {
    #internal-banner {
      .innerPageContent {
        img {
          width: 100% !important;
          margin: auto !important;
        }
      }
      .xinhao {
        width: 100%;
      }
    }
    .popup-content {
      width: 90%;
    }
    #tagline {
      font-size: 18px;
    }
    header {
      button {
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
    nav.open {
      display: block;
    }
    .material-symbols-outlined {
      position: fixed;
      right: 0;
      cursor: pointer;
      display: block;
      background: #f5f5f5;
      color: black;
      padding: 10px;
      border-radius: 4px 0px 0px 4px;
    }
    nav {
      background: white;
      padding: 0px;
      position: fixed;
      width: 100%;
      display: none;
      right: 0px;
      z-index: 1;
      text-align: center;
      top: 0;
      #closeNav {
        color: #000;
        display: block;
        cursor: pointer;
        position: relative;
        text-align: right;
        right: 9px;
        top: 7px;
        width: 20px;
        float: right;
        font-weight: bold;
      }
      .navbar-nav {
        padding: 0px;
        .nav-item {
          display: block;
          a {
            display: block;
            background: #fff;
            color: #3b3b3b;
            padding: 8px 0px;
            z-index: 1;
            border-bottom: 1px solid #c8c8c6;
          }
          a:hover,
          a:active {
            background: #c8c8c6;
            color: #fff;
          }
        }
      }
    }
    #we-here {
      #text {
        width: 100%;
      }
      #pic {
        display: none;
      }
    }
    #testimonial {
      .each-slide-effect > div {
        height: 225px;
      }
      #picture {
        width: 100%;
      }
      #testi {
        clear: both;
        text-align: center;
        width: 100%;
        padding: 10px;
      }
    }
  }
}
