@charset "UTF-8";
@-webkit-keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideInFromRight {
  0% {
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideInFromTop {
  0% {
    -webkit-transform: scale(95%, 95%);
            transform: scale(95%, 95%);
  }
  100% {
    -webkit-transform: scale(100%, 100%);
            transform: scale(100%, 100%);
  }
}

@keyframes slideInFromTop {
  0% {
    -webkit-transform: scale(95%, 95%);
            transform: scale(95%, 95%);
  }
  100% {
    -webkit-transform: scale(100%, 100%);
            transform: scale(100%, 100%);
  }
}

* {
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: arial, Times, "Times New Roman", serif;
  color: white;
}

body {
  background: #698edc;
}

body .animateRight {
  -webkit-animation: 1s ease-out 0s slideInFromRight;
          animation: 1s ease-out 0s slideInFromRight;
}

body .animateLeft {
  -webkit-animation: 1s ease-out 0s slideInFromLeft;
          animation: 1s ease-out 0s slideInFromLeft;
}

body .animateTop {
  -webkit-animation: 1s ease-out 0s slideInFromTop;
          animation: 1s ease-out 0s slideInFromTop;
}

body button {
  color: white;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-box-shadow: 3px 3px 8px #333;
          box-shadow: 3px 3px 8px #333;
  background-color: #698edc;
  cursor: pointer;
}

body input {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  color: #000;
}

body input[type="text"]:focus {
  background-color: #ddd;
  outline: none;
}

body input[type="submit"] {
  background-color: #698edc;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
  font-size: 18px;
  font-weight: bold;
}

#root {
  overflow: hidden;
  position: relative;
  margin: auto;
}

#root .left {
  float: left;
}

#root .right {
  float: right;
}

#root #internal-banner strong,
#root #internal-banner h1,
#root #internal-banner h2,
#root #internal-banner h3,
#root #internal-banner h4,
#root #internal-banner h5,
#root #internal-banner h6,
#root #internal-banner a {
  text-shadow: 1px 2px #000;
  margin-top: 35px;
}

#root #internal-banner .xinhao {
  width: 49%;
}

#root #internal-banner .xinhao textarea {
  width: 100%;
  height: 80vh;
}

#root #internal-banner #about-area {
  line-height: 33px;
}

#root #internal-banner #about-area img {
  float: right;
  border-radius: 20px;
}

#root #internal-banner #serviceUL {
  width: 28%;
  float: left;
  margin: 2% 2%;
}

#root #internal-banner #serviceUL strong {
  margin: 10px 0px;
  display: block;
  font-size: 20px;
  text-decoration: underline;
}

#root #internal-banner #serviceUL ul {
  list-style-type: none;
  padding: 0px 10px;
}

#root #internal-banner #serviceUL ul li {
  font-size: 15px;
  font-weight: bold;
  padding: 5px 0px;
}

#root #internal-banner #servicesPage img {
  float: right;
  width: 510px;
  -webkit-box-shadow: 2px 4px 5px aquamarine;
          box-shadow: 2px 4px 5px aquamarine;
  border-radius: 20px;
  margin: 1px 15px;
}

#root #internal-banner #servicesPage p {
  line-height: 26px;
}

#root #internal-banner .innerPageContent {
  padding: 10px 10%;
  font-size: 18px;
}

#root #internal-banner .innerPageContent p {
  margin-top: 20px;
}

#root #internal-banner #banner-part {
  width: 100%;
  -webkit-animation: 1s ease-out 0s slideInFromLeft;
          animation: 1s ease-out 0s slideInFromLeft;
}

#root #internal-banner #banner-part img {
  width: 100%;
}

#tagline {
  text-align: center;
  font-size: 35px;
}

#module-title {
  text-transform: uppercase;
  text-align: center;
  padding: 50px 0px;
}

.clr {
  clear: both;
}

header {
  margin-top: 15px;
  background: url(../images/logo-ast-construction.png) no-repeat 10% center #f5f5f5;
  position: relative;
  padding: 50px 0px;
}

header a {
  position: absolute;
  display: block;
  text-align: center;
  color: #fa0000;
  font-weight: bold;
  float: right;
  top: 16px;
  right: 48px;
}

header button {
  right: 50px;
  float: right;
  position: relative;
}

.material-symbols-outlined {
  display: none;
}

nav {
  float: right;
  position: relative;
  right: 50px;
}

nav #closeNav {
  display: none;
}

nav ul {
  list-style: none;
  padding: 35px 0px;
}

nav ul a {
  text-decoration: none;
  color: #fff;
  padding: 0px 15px;
  font-weight: bold;
}

#banner {
  margin-top: 10px;
}

#banner #banner-part {
  float: left;
  width: 30%;
  text-align: center;
  -webkit-animation: 1s ease-out 0s slideInFromTop;
          animation: 1s ease-out 0s slideInFromTop;
}

#banner #banner-area {
  float: left;
  width: 68%;
  -webkit-animation: 1s ease-out 0s slideInFromLeft;
          animation: 1s ease-out 0s slideInFromLeft;
  font-family: "Poller One", serif;
  font-size: 108px;
  float: right;
}

#banner h5 {
  padding-left: 9%;
  font-size: 16px;
}

#banner h5::before {
  content: "\➺";
}

#testimonial .react-slideshow-container .nav:first-of-type {
  right: 0 !important;
  left: auto;
  margin-right: 60px !important;
}

#testimonial .react-slideshow-container .default-nav {
  background: white;
  bottom: 0;
}

#testimonial .each-slide-effect > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-size: cover;
  height: 350px;
}

#testimonial .each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

#testimonial #picture {
  float: left;
  width: 30%;
  text-align: center;
}

#testimonial #picture img {
  margin: auto;
  position: relative;
}

#testimonial #testi {
  float: left;
  text-align: center;
  width: 68%;
  line-height: 30px;
  padding: 25px 0px;
  font-size: 26px;
}

#we-here {
  margin: 100px 0px;
}

#we-here #text {
  float: left;
  width: 50%;
  padding: 5px 10px;
  text-align: center;
  line-height: 25px;
}

#we-here #pic {
  -webkit-animation: 1s ease-out 0s slideInFromLeft;
          animation: 1s ease-out 0s slideInFromLeft;
  float: right;
  text-align: center;
  width: 49%;
}

footer img {
  width: 100%;
}

.popup-content {
  -webkit-animation: 1s ease-out 0s slideInFromLeft;
          animation: 1s ease-out 0s slideInFromLeft;
  left: auto !important;
  width: 36% !important;
  right: 0 !important;
  padding: 25px !important;
  color: #333;
}

.popup-content div {
  left: auto !important;
  right: 18%;
}

.popup-content h1 {
  color: #333;
}

.popup-content form label {
  color: #333;
  font-size: 18px;
  font-weight: bold;
}
