$basecolor: #698edc;
$shadow: 3px 3px 8px #333;

@keyframes slideInFromLeft {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromTop {
  0% {
    transform: scale(95%, 95%);
  }
  100% {
    transform: scale(100%, 100%);
  }
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: arial, Times, "Times New Roman", serif;
  color: white;
}

body {
  background: $basecolor;

  .animateRight {
    animation: 1s ease-out 0s slideInFromRight;
  }
  .animateLeft {
    animation: 1s ease-out 0s slideInFromLeft;
  }
  .animateTop {
    animation: 1s ease-out 0s slideInFromTop;
  }
  button {
    color: white;
    padding: 10px;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: $shadow;
    background-color: $basecolor;
    cursor: pointer;
  }
  input {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    color: #000;
  }
  input[type="text"]:focus {
    background-color: #ddd;
    outline: none;
  }
  input[type="submit"] {
    background-color: $basecolor;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    opacity: 0.8;
    font-size: 18px;
    font-weight: bold;
  }
}
#root {
  overflow: hidden;
  position: relative;
  margin: auto;
  .left {
    float: left;
  }
  .right {
    float: right;
  }
  #internal-banner {
    strong,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a {
      text-shadow: 1px 2px #000;
      margin-top: 35px;
    }
    .xinhao {
      width: 49%;
      textarea {
        width: 100%;
        height: 80vh;
      }
    }
    #about-area {
      line-height: 33px;
      img {
        float: right;
        border-radius: 20px;
      }
    }
    #serviceUL {
      width: 28%;
      float: left;
      margin: 2% 2%;
      strong {
        margin: 10px 0px;
        display: block;
        font-size: 20px;
        text-decoration: underline;
      }
      ul {
        list-style-type: none;
        padding: 0px 10px;

        li {
          font-size: 15px;
          font-weight: bold;
          padding: 5px 0px;
        }
      }
    }
    #servicesPage {
      img {
        float: right;
        width: 510px;
        box-shadow: 2px 4px 5px aquamarine;
        border-radius: 20px;
        margin: 1px 15px;
      }
      p {
        line-height: 26px;
      }
    }
    .innerPageContent {
      padding: 10px 10%;
      font-size: 18px;

      p {
        margin-top: 20px;
      }
    }
    #banner-part {
      width: 100%;
      animation: 1s ease-out 0s slideInFromLeft;
      img {
        width: 100%;
      }
    }
  }
}
#tagline {
  text-align: center;
  font-size: 35px;
}
#module-title {
  text-transform: uppercase;
  text-align: center;
  padding: 50px 0px;
}
.clr {
  clear: both;
}
header {
  margin-top: 15px;
  background: url(../images/logo-ast-construction.png) no-repeat 10% center
    #f5f5f5;
  position: relative;
  padding: 50px 0px;
  a {
    position: absolute;
    display: block;
    text-align: center;
    color: #fa0000;
    font-weight: bold;
    float: right;
    top: 16px;
    right: 48px;
  }
  button {
    right: 50px;
    float: right;
    position: relative;
  }
}
.material-symbols-outlined {
  display: none;
}
nav {
  float: right;
  position: relative;
  right: 50px;
  #closeNav {
    display: none;
  }
  ul {
    list-style: none;
    padding: 35px 0px;
    a {
      text-decoration: none;
      color: #fff;
      padding: 0px 15px;
      font-weight: bold;
    }
  }
}

#banner {
  margin-top: 10px;

  #banner-part {
    float: left;
    width: 30%;
    text-align: center;
    animation: 1s ease-out 0s slideInFromTop;
  }
  #banner-area {
    float: left;
    width: 68%;
    animation: 1s ease-out 0s slideInFromLeft;
    font-family: "Poller One", serif;
    font-size: 108px;
    float: right;
  }
  h5 {
    padding-left: 9%;
    font-size: 16px;
  }
  h5::before {
    content: "\➺";
  }
}
#testimonial {
  .react-slideshow-container {
    .nav:first-of-type {
      right: 0 !important;
      left: auto;
      margin-right: 60px !important;
    }
    .default-nav {
      background: white;
      bottom: 0;
    }
  }
  .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }

  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
  #picture {
    float: left;
    width: 30%;
    text-align: center;
    img {
      margin: auto;
      position: relative;
    }
  }
  #testi {
    float: left;
    text-align: center;
    width: 68%;
    line-height: 30px;
    padding: 25px 0px;
    font-size: 26px;
  }
}
#we-here {
  margin: 100px 0px;
  #text {
    float: left;
    width: 50%;
    padding: 5px 10px;
    text-align: center;
    line-height: 25px;
  }
  #pic {
    animation: 1s ease-out 0s slideInFromLeft;
    float: right;
    text-align: center;
    width: 49%;
  }
}
footer {
  img {
    width: 100%;
  }
}

// header popup
.popup-content {
  // width: 60% !important;
  // left: 0px !important;
  animation: 1s ease-out 0s slideInFromLeft;
  left: auto !important;
  width: 36% !important;
  right: 0 !important;
  padding: 25px !important;
  color: #333;

  div {
    left: auto !important;
    right: 18%;
  }
  h1 {
    color: #333;
  }
  form {
    label {
      color: #333;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
